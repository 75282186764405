<script>
import { onMounted, onUnmounted, ref, watch } from "vue";
import { signUp, sendCode } from "@/http/api";
import countDown from "@/components/countdown";
import md5 from "md5";
import router from "@/routes";
import { http } from "@/http";
import { needLoading, userAuth } from "@/reactive";
import { Toast, Dialog } from "vant";
import { useRoute, useRouter } from "vue-router";
import { getIphoneThreeFourFour } from "@/utils";

export default {
  components: {
    countDown,
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
    const VanDialog = Dialog.Component;
    let tel = ref("");
    let formatTel = ref("");
    let password = ref("");
    let code = ref("");
    let checked = ref(false);
    let clickable = ref(false);
    let slogan = ref("");
    let route = useRoute();
    let router = useRouter();
    const smallTransId = route.query.smallTransId;
    let dialogShow = ref(false);
    let pageshow = ref(false);
    let dialogInfo = ref('');
    let dialogTel = ref('')

    const onSubmit = (fields) => {
      tel.value = tel.value.replace(/\s*/g, "");
      if (!clickable.value) {
        Toast("请输入正确手机号");
        return false;
      }
      if (needLoading.value) {
        return false;
      }
      needLoading.value = true;
      signUp({
        loginType: "CAPTCHA",
        tel: tel.value,
        captchaCode: fields.code,
        regFlag: false,
        smallTransId: smallTransId,
      })
        .then((data) => {
          if (data.result) {
            Toast.success("猪耳朵账号注册成功!");
            router.push("/signup-success");
            //window.localStorage.setItem('userAuth', JSON.stringify(data.result))
          }
          if (data.errCode == 32011) {
            dialogShow.value = true;
            dialogTel.value = data.errDesc.substring(data.errDesc.indexOf('<kftel>')+7,data.errDesc.length-8)
            dialogInfo.value = data.errDesc.substring(0,data.errDesc.indexOf('<kftel>'))
          }
        })
        .catch(() => {});
    };

    const handleSendCode = (a) => {
      tel.value = tel.value.replace(/\s*/g, "");
      if (!clickable.value) {
        Toast("请输入正确手机号");
        return false;
      }
      sendCode({
        templateCode: "SMS_MERCHANT_LOGIN_REGIST",
        smsPhoneNumber: tel.value,
      }).then((data) => {});
    };

    const confirm = () => {
      window.location.href = "tel://" + dialogTel.value
    }

    watch(
      () => tel.value,
      (a, b) => {
        tel.value = getIphoneThreeFourFour(a);
        let stra = a.replace(/\s*/g, "");
        clickable.value = /^1[3456789][0-9]{9}$/.test(stra);
      }
    );

    onMounted(() => {
      http
        .post(
          "/?cmd=com.xqxc.api.pigear.biz.account.UserLoginService.smallTransIdIsValid",
          { id: smallTransId }
        )
        .then((res) => {
          if (!res.result) {
            router.push("/errorUrl");
          } else {
            pageshow.value = true;
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    });

    return {
      onSubmit,
      tel,
      formatTel,
      code,
      password,
      checked,
      clickable,
      slogan,
      handleSendCode,
      dialogShow,
      pageshow,
      VanDialog,
      dialogInfo,
      dialogTel,
      confirm
    };
  },
};
</script>
<template>
  <div class="page">
    <div v-show="pageshow">
      <van-form @submit="onSubmit" validate-trigger="onSubmit">
        <!-- <h3>商户账号注册<p class="slogan">{{slogan}}</p></h3> -->
        <h3>
          猪耳朵商户注册
          <p class="slogan">猪耳朵省时，省力，更省钱，聚合平台领跑者</p>
        </h3>
        <van-field
          v-model="tel"
          name="tel"
          maxlength="13"
          type="number"
          placeholder="请输入手机号码"
          style="border-bottom: 1px solid #dbdbdb"
        />
        <van-field
          v-model="code"
          label-width="1rem"
          name="code"
          maxlength="4"
          type="number"
          placeholder="请输入验证码"
          style="border-bottom: 1px solid #dbdbdb"
          :rules="[
            {
              required: true,
              message: '请输入验证码',
            },
          ]"
        >
          <template #extra>
            <countDown
              :clickControlled="clickable"
              @handleSend="handleSendCode"
            >
              <span class="vcode">发送验证码</span>
            </countDown>
          </template>
        </van-field>
        <!-- <van-field
        v-model="password"
        type="password"
        name="password"
        placeholder="请输入密码"
        style="border-bottom:1px solid #dbdbdb"
        :rules="[{ 
          required: true, 
          message: '请输入密码' 
        }, 
        // {
        //   pattern: /^[a-zA-Z0-9]{6,16}$/, 
        //   message: '请输入6-16位数字字母组合密码'
        // }
        ]"
      /> -->
        <div style="margin: 30px 0 0">
          <van-button block type="primary" native-type="submit"
            >注册并登录</van-button
          >
        </div>
        <div style="margin-top: 20px; text-align: center">
          <p
            style="
              margin-top: 20px;
              font-size: 0.24rem;
              text-align: center;
              color: #666;
            "
          >
            <!-- <van-checkbox v-model="checked" checked-color="#00abeb" icon-size="14px" shape="square"> -->
            注册代表已阅读并同意<a
              @click="
                $router.push(
                  '/blend-view/agreement?agreementType=MERCHANT_USER_PROTOCOL'
                )
              "
              style="color: #00abeb"
              >《用户协议》</a
            >与<a
              @click="
                $router.push(
                  '/blend-view/agreement?agreementType=MERCHANT_PRIVACY_POLICY'
                )
              "
              style="color: #00abeb"
              >《隐私政策》</a
            >
            <!-- </van-checkbox> -->
          </p>
        </div>
      </van-form>
      <van-dialog v-model:show="dialogShow" confirm-button-text="联系客服" @confirm="confirm" show-cancel-button>
        <div class="dialog-info">{{dialogInfo}}</div>
      </van-dialog>
    </div>
  </div>
</template>
<style scoped>
.page {
  height: 100%;
  overflow: auto;
  padding: 0 0.6rem;
  box-sizing: border-box;
}
.concat {
  line-height: 1.5;
  font-size: 0.24rem;
  text-align: center;
}
.concat p {
  padding-top: 80%;
}
h3 {
  margin: 1rem 0;
  font-size: 0.46rem;
}
.van-cell::after {
  border: none;
}
.vcode {
  color: #00abeb;
  font-size: 0.26rem;
}
.slogan {
  margin-top: 0.3rem;
  font-size: 0.28rem;
  font-weight: normal;
  color: #999;
}
.dialog-info{
  padding:.4rem;
  color: #333333;
  font-size: .30rem;
  line-height: .4rem;
}
</style>